interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export default function Button({ children, className, ...rest }: ButtonProps) {
  return (
    <button
      className={`p-2 rounded text-ellipsis ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
}
