import { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";

import { Redirect, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./App.css";
import MainLayout from "./layouts/main";

const App = ({ isServerInfo }) => {
  const [t] = useTranslation("global");
  const { isWeb3Enabled, enableWeb3, isAuthenticated, isWeb3EnableLoading } =
    useMoralis();
  const [initializationTries, setInitializationTries] = useState(0);
  const location = useLocation();

  useEffect(() => {
    setInitializationTries(initializationTries + 1);
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading) {
      enableWeb3();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isWeb3Enabled]);

  if (initializationTries < 1) {
    return <Redirect to={location.pathname} />;
  }

  return <MainLayout />;
};

export default App;
