import { useEffect, useState } from "react";
import { useMoralis, useMoralisCloudFunction } from "react-moralis";

export default function useUserInfo() {
  const { user, isAuthenticated } = useMoralis();
  const [role, setRole] = useState(null);
  const { data, error, fetch, isLoading } = useMoralisCloudFunction(
    "getUserRole",
    null,
    { autoFetch: false }
  );

  useEffect(() => {
    fetch();
  }, [user]);

  useEffect(() => {
    if (data) {
      const roles = JSON.parse(JSON.stringify(data));
      setRole(roles);
    } else {
      setRole(null);
    }
  }, [data]);

  return {
    user,
    role,
    isAuthenticated,
  };
}
