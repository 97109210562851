import { useEffect, useState } from "react";
import { useMoralis, useMoralisCloudFunction } from "react-moralis";
import { DEFAULT_NETWORK } from "../constants";
import { useMoralisDapp } from "../providers/MoralisDappProvider/MoralisDappProvider";

export const useNFTSearch = (
  queryText: String,
  skip: number = 0,
  limit: number = 8
) => {
  const { chainId } = useMoralisDapp();
  const [results, setResults] = useState([]);
  const [total, setTotal] = useState(0);

  const { data, error, isLoading, fetch, isFetching } = useMoralisCloudFunction(
    "searchNfts",
    {
      text: queryText,
      chainId: chainId ? chainId : DEFAULT_NETWORK,
      skip,
      limit,
    },
    { autoFetch: false }
  );

  useEffect(() => {
    if (data && data.length > 0) {
      const searchResults = data[0];
      setTotal(searchResults.info[0]?.count || 0);
      if (searchResults.results && searchResults.results.length > 0) {
        let results = JSON.parse(JSON.stringify(searchResults.results));
        setResults(results);
      }
    }
  }, [data]);

  return {
    results,
    total,
    isLoading: isLoading || isFetching,
    fetch,
  };
};

const ADDRESS_REGEX = /^0x[a-fA-F0-9]{13,40}$/;

export const useCollectionSearch = (
  queryText: string = null,
  skip: number = 0,
  limit: number = 8,
  customSort: any = null
) => {
  const { chainId } = useMoralisDapp();
  const [results, setResults] = useState([]);
  const [total, setTotal] = useState(0);

  let params = {
    chainId: chainId ? chainId : DEFAULT_NETWORK,
    skip,
    limit,
    ...(customSort ? {sort: customSort}:{})
  };
  if (ADDRESS_REGEX.test(queryText)) {
    params = {
      owner: queryText.toLowerCase(),
      ...params,
    };
  } else {
    params = {
      text: queryText,
      ...params,
    };
  }

  const { data, error, isLoading, fetch, isFetching } = useMoralisCloudFunction(
    "searchCollectionV2",
    params,
    { autoFetch: false }
  );

  useEffect(() => {
    if (data && data.length > 0) {
      let results = JSON.parse(JSON.stringify(data));
      setResults(results[0].results);
      if (results[0]!.info[0]) setTotal(results[0]!.info[0].count);
    } else {
      setResults([]);
    }
  }, [data]);

  return {
    results,
    total,
    isLoading: isLoading || isFetching,
    fetch,
  };
};
