import { AspectRatio, Badge, Box, Center, SimpleGrid } from "@chakra-ui/react";
import ImageWithFallback from "../../../_commons/ImageWithFallback";
import { weiToTokenValue, getSymbol } from "../../../../helpers/formatters";
import { useMoralis } from "react-moralis";
import { useMoralisDapp } from "providers/MoralisDappProvider/MoralisDappProvider";
import { useTranslation } from "react-i18next";

import styles from "./index.module.css";

export default function NFTCard({ nft, index, handleClic }) {
  const { Moralis } = useMoralis();
  const { chainId } = useMoralisDapp();
  const [t] = useTranslation("global");

  function daysLeft(createdAt, auctionBidPeriod) {
    const d = new Date(createdAt);
    d.setSeconds(d.getSeconds() + auctionBidPeriod / 1);

    const localDate = new Date();
    const diffTime = Math.abs(d - localDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays > 0) return diffDays + " days left";
    else return "Expired";
  }

  let price = null;
  let currency = null;
  let isAuction = false;
  let isSale = false;

  if (Array.isArray(nft.SaleData) || Array.isArray(nft.AuctionData)) {
    if (nft.SaleData.length > 0) {
      isSale = true;
      price = nft.SaleData[0].buyNowPrice;
      currency = nft.SaleData[0].erc20Token;
    } else if (nft.AuctionData.length > 0) {
      isAuction = true;
      currency = nft.AuctionData[0].erc20Token;
      if (nft.AuctionData[0].latestOffer) {
        price = nft.AuctionData[0].latestOffer;
      } else {
        price = nft.AuctionData[0].minPrice;
      }
    }
  } else {
    if (nft.SaleData) {
      isSale = true;
      price = nft.SaleData.buyNowPrice;
      currency = nft.SaleData.erc20Token;
    } else if (nft.AuctionData) {
      isAuction = true;
      currency = nft.AuctionData.erc20Token;
      if (nft.AuctionData.latestOffer) {
        price = nft.AuctionData.latestOffer;
      } else {
        price = nft.AuctionData.minPrice;
      }
    }
  }

  return (
    <Box
      className={`${styles.box} ease-in-out duration-500`}
      // mb={10}
      key={index}
      onClick={() => {
        handleClic(nft);
      }}
    >
      <AspectRatio width="100%" maxWidth="100%" ratio={1}>
        <ImageWithFallback
          borderRadius={15}
          src={nft?.image || "error"}
          alt="dd"
          style={{ objectFit: "cover" }}
        />
      </AspectRatio>

      <div className="flex mt-[24px] mx-2">
        <div className="flex flex-col flex-[1_1_auto] overflow-hidden justify-center">
          <span className="title !text-[20px] truncate">{nft.name}</span>
          <span className="text-[12px] leading-7">#{nft.tokenId}</span>
        </div>
        <div className="flex flex-col flex-[1_1_auto] justify-start items-end text-[20px]">
          <p className="text-right font-poppins">
            {price ? weiToTokenValue(Moralis, price) : ""}{" "}
            <span className="font-medium">{currency ? getSymbol(currency, chainId) : ""}</span>
          </p>
          <div>
            {isAuction ? (
              <Badge colorScheme="green" className="!text-[10px] !px-3 py-1">
                {t("general.auction")}
              </Badge>
            ) : (
              isSale && (
                <Badge colorScheme="green" className="!text-[10px] !px-3 py-1">
                  {t("general.buyNow")}
                </Badge>
              )
            )}
          </div>
        </div>
      </div>
    </Box>
  );
}
