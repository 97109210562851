import { Skeleton } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { useCollectionSearch } from "../../../hooks/useNFTSearch";
import CollectionCard from "../Shared/CollectionCard";
import styles from "./index.module.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

interface CollectionsProps {
  queryText: string;
}

export default function Collections({ queryText }: CollectionsProps) {
  const [collections, setCollections] = useState([]);
  const { results, fetch } = useCollectionSearch("");
  const { t } = useTranslation("global");

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    setCollections(results);
  }, [results]);

  return (
    <div className="container max-w-[1440px] mx-auto">
      <div className="py-[30px] md:py-[120px]">
        <div className="font-bold text-[24px] text-titulos mb-[14px] text-center">
          {t("explore.bestCollections")}
        </div>
        <div
          className={`mx-4 ${collections.length < 3 ? styles.centered : styles.mobile}`}
        >
          {collections && collections.length > 0 ? (
            <Carousel
              showDots
              responsive={responsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={4000}
              keyBoardControl={true}
            >
              {collections.map((it, index) => (
                <CollectionCard collection={it} key={index} />
              ))}
            </Carousel>
          ) : (
            <div className="columns-3">
              {[1, 2, 3].map((it) => (
                <Skeleton key={it} height={300} />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
