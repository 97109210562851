import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={16}
    fill="#fff"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 16"
    {...props}
  >
    <path
      d="M19.233 1.837a7.93 7.93 0 0 1-2.265.616A3.936 3.936 0 0 0 18.702.286a7.88 7.88 0 0 1-2.506.95A3.956 3.956 0 0 0 13.316 0C11.136 0 9.37 1.753 9.37 3.916c0 .306.036.606.103.892A11.228 11.228 0 0 1 1.34.716a3.904 3.904 0 0 0 1.22 5.229 3.938 3.938 0 0 1-1.785-.49.425.425 0 0 0-.002.05c0 1.898 1.36 3.481 3.165 3.84a4.001 4.001 0 0 1-1.781.067 3.946 3.946 0 0 0 3.686 2.72A7.953 7.953 0 0 1 .942 13.81c-.319 0-.632-.019-.942-.055a11.228 11.228 0 0 0 6.049 1.76c7.258 0 11.226-5.968 11.226-11.143 0-.17-.004-.34-.01-.507a7.959 7.959 0 0 0 1.968-2.027Z"
    />
  </svg>
)

export default SvgComponent
