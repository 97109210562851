import { Center, Spinner } from "@chakra-ui/react";
import { ReactElement, Suspense } from "react";

interface SuspenseCustomProps {
    children: ReactElement
}
export default function SuspenseCustom({children}: SuspenseCustomProps) {
    const fallbackComponent = (
        <Center minHeight={400}>
            <Spinner size='xl' />
        </Center>
    )
    return (
        <Suspense fallback={fallbackComponent}>
            {children}
        </Suspense>
    )
}