import getUnicodeFlagIcon from "country-flag-icons/unicode";
import global_en from "./en/global.json";
import global_es from "./es/global.json";

export const VALID_LANGUAGES = ["en", "es"];

const i18n = {
  interpolation: { escapeValue: false },
  lng: VALID_LANGUAGES.some((lang) => lang === localStorage.getItem("languageLocalStorage"))
    ? localStorage.getItem("languageLocalStorage")
    : "en",
  resources: {
    en: {
      global: global_en,
      label: "English",
      flag: getUnicodeFlagIcon("US"),
    },
    es: {
      global: global_es,
      label: "Español",
      flag: getUnicodeFlagIcon("ES"),
    },
  },
};

export default i18n;
