import { useMoralisDapp } from "../providers/MoralisDappProvider/MoralisDappProvider";
import { useEffect, useState } from "react";
import { useMoralis, useMoralisCloudFunction } from "react-moralis";
import { PAGE_SIZE } from "../helpers/constants";
import { useMoralisWeb3Api } from "react-moralis";
import { DEFAULT_NETWORK } from "../constants";

export const useNFTDBNewNfts = (skip = 0, limit = PAGE_SIZE) => {
  const { chainId } = useMoralisDapp();
  const { Moralis } = useMoralis();
  const [newestTokens, setNewestTokens] = useState([]);
  const [fetching, setFeching] = useState(false);

  const getNewestTokens = async () => {
    setFeching(true);
    try {
      const newChainId = chainId ? chainId : DEFAULT_NETWORK;
      const result = await Moralis.Cloud.run("getNewNfts", {
        chainId: newChainId,
        skip,
        limit,
      });
      const parseResult = JSON.parse(JSON.stringify(result));
      if (Array.isArray(parseResult)) setNewestTokens(parseResult);
      else {
        console.warn(parseResult);
      }
    } catch (e) {
      console.warn(e);
    } finally {
      setFeching(false);
    }
  };
  useEffect(() => {
    getNewestTokens();
  }, [skip, limit]);
  //console.log(newestTokens)
  return {
    newestTokens,
    fetching
  };
};

export const useTransactionsOfNFT = (nftAddress, tokenId, skip, limit) => {
  const [transactions, setTransactions] = useState([]);
  const [total, setTotal] = useState(0);

  const {
    fetch: fetchSalesTransactions,
    data: dataSalesTransactions,
    error: errorSalesTransactions,
    isLoading: isLoadingSalesTransactions,
  } = useMoralisCloudFunction(
    "loadTransactions",
    {
      nftAddress,
      tokenId: `${tokenId}`,
      skip,
      limit,
    },
    { autoFetch: false }
  );

  useEffect(() => {
    if (
      !errorSalesTransactions &&
      !isLoadingSalesTransactions &&
      dataSalesTransactions
    ) {
      setTotal(dataSalesTransactions.total);
      let salesTransactions = JSON.parse(
        JSON.stringify(dataSalesTransactions.results)
      );
      salesTransactions = salesTransactions.map((it) => ({
        ...it,
        type: "Sale",
      }));
      joinAndOrder(salesTransactions);
    }
  }, [dataSalesTransactions, isLoadingSalesTransactions]);

  const {
    fetch: fetchAutionsTransactions,
    data: dataAutionsTransactions,
    error: errorAutionsTransactions,
    isLoading: isLoadingAutionsTransactions,
  } = useMoralisCloudFunction(
    "loadAuctionTransactions",
    {
      nftAddress,
      tokenId: `${tokenId}`,
      skip,
      limit,
    },
    { autoFetch: false }
  );

  useEffect(() => {
    if (
      !errorAutionsTransactions &&
      !isLoadingAutionsTransactions &&
      dataAutionsTransactions
    ) {
      setTotal(dataAutionsTransactions.total);
      let autionsTransactions = JSON.parse(
        JSON.stringify(dataAutionsTransactions.results)
      );
      autionsTransactions = autionsTransactions.map((it) => ({
        ...it,
        type: "Auction",
      }));
      joinAndOrder(autionsTransactions);
    }
  }, [dataAutionsTransactions, isLoadingAutionsTransactions]);

  const fetchSalesAndAuctionTransactions = () => {
    setTransactions([]);
    fetchSalesTransactions();
    fetchAutionsTransactions();
  };

  const joinAndOrder = (newTransactions) => {
    const allTransactions = [...transactions, ...newTransactions];
    allTransactions.sort((a, b) => b.block_number - a.block_number);
    setTransactions(allTransactions.slice(0, 5));
  };

  return {
    total,
    transactions,
    isLoading: isLoadingSalesTransactions || isLoadingAutionsTransactions,
    fetch: fetchSalesAndAuctionTransactions,
  };
};

export const useNFTTrades = (nftAddress, tokenId) => {
  const Web3Api = useMoralisWeb3Api();

  const fetchNFTTrades = async () => {
    const options = {
      address: nftAddress,
      token_id: tokenId,
      limit: "10",
      chain: "bsc testnet",
    };
    const NFTTrades = await Web3Api.token.getWalletTokenIdTransfers(options);
    return NFTTrades;
  };

  return {
    fetchNFTTrades,
  };
};
