import React, { useEffect, useState } from "react";
import { useMoralis, useMoralisCloudFunction } from "react-moralis";
import MoralisDappContext from "./context";
import abi from "../../contracts/contractInfo.json";
import newCollectionAbi from "../../contracts/newCollectionContract.json";
import bulkAbi from "../../contracts/bulkInsertContract.json";
import { useToast } from "@chakra-ui/react";
import {
  MARKET_SMART_CONTRACT_ADDRESS,
  COLLECTION_CREATOR_ADDRESS,
  BULK_COLLECTION_CREATOR_ADDRESS,
} from "../../constants";
import { useTranslation } from "react-i18next";

function MoralisDappProvider({ children }) {
  const { t } = useTranslation("global");
  const { chainId, account, Moralis, user, authenticate, logout } =
    useMoralis();
  // const [walletAddress, setWalletAddress] = useState();
  // const [chainId, setChainId] = useState();
  const [contractABI, setContractABI] = useState(JSON.stringify(abi.abi));
  const [newCollectionContractABI] = useState(
    JSON.stringify(newCollectionAbi.newCollectionAbi)
  );
  const [bulkContractABI] = useState(JSON.stringify(bulkAbi.bulkAbi));
  const [marketAddress, setMarketAddress] = useState(
    MARKET_SMART_CONTRACT_ADDRESS
  ); //Smart Contract Address Here
  const collectionAddress = COLLECTION_CREATOR_ADDRESS;
  const bulkAddress = BULK_COLLECTION_CREATOR_ADDRESS;
  const [erc20Tokens, setErc20Tokens] = useState([]);

  const toast = useToast();

  const { data: erc20TokensRaw } = useMoralisCloudFunction("getErc20Tokens");

  useEffect(() => {
    if (erc20TokensRaw) {
      setErc20Tokens(JSON.parse(JSON.stringify(erc20TokensRaw)));
    }
  }, [erc20TokensRaw]);

  useEffect(() => {
    Moralis.onChainChanged(function (chain) {
      // setChainId(chain);
      window.location = "/";
    });

    Moralis.onAccountChanged(function (address) {
      toast({
        title: "Account changed detected",
        description: address,
        status: "info",
      });
      console.log("address", address);

      if (address && address.length > 0) {
        toast({
          title: "New sing up required",
          description: "Please accept sing up request on Metamask",
          status: "warning",
          duration: 10 * 1000,
        });
        authenticate({
          signingMessage: t("general.company"),
        });
      } else {
        console.log("Just logout");
        logout().then((result) => console.log("logout success"));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(() => setChainId(web3.givenProvider?.chainId));
  // useEffect(
  //   () => setWalletAddress(web3.givenProvider?.selectedAddress || user?.get("ethAddress")),
  //   [web3, user]
  // );

  return (
    <MoralisDappContext.Provider
      value={{
        walletAddress: account,
        chainId,
        marketAddress,
        setMarketAddress,
        contractABI,
        setContractABI,
        newCollectionContractABI,
        collectionAddress,
        bulkContractABI,
        bulkAddress,
        erc20Tokens
      }}
    >
      {children}
    </MoralisDappContext.Provider>
  );
}

function useMoralisDapp() {
  const context = React.useContext(MoralisDappContext);
  if (context === undefined) {
    throw new Error("useMoralisDapp must be used within a MoralisDappProvider");
  }
  return context;
}

export { MoralisDappProvider, useMoralisDapp };
