import { lazy, useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMoralis } from "react-moralis";

import SuspenseCustom from "../components/_commons/SuspenseCustom";
import Navigation from "../Navigation";
import { DEFAULT_NETWORK } from "../constants";
import useUserInfo from "../hooks/useUserInfo";
import { useMoralisDapp } from "../providers/MoralisDappProvider/MoralisDappProvider";

//Routes
import { Home } from "../Home";
import { Profile } from "../Profile";
import { Auth } from "../Auth";
import { Container } from "@chakra-ui/react";
import Footer from "components/Footer";

const Wallet = lazy(() => import("../components/Wallet"));
const NFTBalance = lazy(() => import("../components/NFTBalance"));
const Mint = lazy(() => import("../components/Mint/index"));
const MySells = lazy(() => import("../components/MySells"));
const MyCollections = lazy(() => import("../components/Account/MyCollections"));
const AdminConsole = lazy(() => import("../components/Admin"));
const Finder = lazy(() => import("../components/Finder"));
const CollectionsProfile = lazy(
  () => import("../components/CollectionsProfile/index")
);
const ExploreCollection = lazy(() => import("../components/Home/NFTTokenIds"));
const UserProfile = lazy(
  () => import("../components/UserProfile/index")
);
const UserCollectionProfile = lazy(
  () => import("../components/UserProfile/ProfileCollection")
);
const NFTProfile = lazy(() => import("../components/NFTProfile/index"));
const AllNFTs = lazy(() => import("../components/AllNFTs"));

const ADMIN_ROLE = "admin";

export default function MainLayout() {
  const [t] = useTranslation("global");
  const { isWeb3Enabled, enableWeb3, isAuthenticated, isWeb3EnableLoading } =
    useMoralis();
  const [initializationTries, setInitializationTries] = useState(0);
  const { role } = useUserInfo();
  const { chainId } = useMoralisDapp();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0,0);
  },[location.pathname]);

  useEffect(() => {
    setInitializationTries(initializationTries + 1);
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading) {
      enableWeb3();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isWeb3Enabled]);

  if (initializationTries < 1) {
    return <Redirect to={location.pathname} />;
  }

  const isAdmin = role && role.some((it) => it.name === ADMIN_ROLE);

  return (
    <div>
      <>
        <Navigation />
      </>

      <div className="w-full min-h-[100vh] bg-white text-textos">
        <div className="h-[64px]" />
        <SuspenseCustom>
          {isAuthenticated && chainId === DEFAULT_NETWORK ? (
            <Switch>
              <Route path="/profile">
                <Profile />
              </Route>
              {/* <Route path="/DEX">
              <DEX chain="bsc"/>
            </Route> */}
              <Route path="/wallet">
                <Wallet />
              </Route>
              <Route path="/nftBalance">
                <NFTBalance />
              </Route>
              <Route path="/mint">
                <Mint />
              </Route>
              <Route path="/mySells">
                <MySells />
              </Route>
              <Route path="/myCollections">
                <MyCollections />
              </Route>
              <Route path="/finder">
                <Finder />
              </Route>
              <Route path="/exploreCollection">
                <ExploreCollection />
              </Route>
              <Route path="/allnfts">
                <AllNFTs />
              </Route>
              {isAdmin && (
                <Route path="/admin">
                  <AdminConsole />
                </Route>
              )}
              <Route path="/collectionsProfile/:address/:type">
                <CollectionsProfile />
              </Route>
              <Route exact path="/userProfile/:address/">
                <UserProfile />
              </Route>
              <Route exact path="/userProfile/:userAddress/:collectionAddress/">
                <UserCollectionProfile />
              </Route>
              <Route path="/nft/:address/:tokenId">
                <NFTProfile />
              </Route>
              <Route path="/" exact>
                <Home />
              </Route>
              <Redirect to="/" />
            </Switch>
          ) : (
            <Switch>
              <Route path="/login">
                <Auth />
              </Route>
              <Route path="/finder">
                <Finder />
              </Route>
              <Route path="/collectionsProfile/:address/:type">
                <CollectionsProfile />
              </Route>
              <Route exact path="/userProfile/:address/">
                <UserProfile />
              </Route>
              <Route exact path="/userProfile/:userAddress/:collectionAddress/">
                <UserCollectionProfile />
              </Route>
              <Route path="/nft/:address/:tokenId">
                <NFTProfile />
              </Route>
              <Route path="/exploreCollection">
                <ExploreCollection />
              </Route>
              <Route path="/allnfts">
                <AllNFTs />
              </Route>
              <Route path="/" exact>
                <Home />
              </Route>
              <Redirect to="/" />
            </Switch>
          )}
        </SuspenseCustom>
      </div>
      <Footer />
    </div>
  );
}
