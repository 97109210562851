import {
  Box,
  Heading,
  Table,
  TableCaption,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Td,
  Th,
  SimpleGrid,
  Image,
  Wrap,
  WrapItem,
  Center,
  Flex,
  Skeleton,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import { useMoralisDapp } from "../../providers/MoralisDappProvider/MoralisDappProvider";
import fallbackImg from "../../helpers/fallbackImg";
import ImageWithFallback from "components/_commons/ImageWithFallback";
import { DEFAULT_NETWORK } from "../../constants";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";

export const TopSellers = () => {
  const { t, i18n } = useTranslation("global");
  const { Moralis } = useMoralis();
  const { chainId } = useMoralisDapp();
  const [topSellers, setTopSellers] = useState([]);
  const styles = {
    textOrange: {
      color: "#cd9b4c",
      fontWeight: "bold",
    },
  };

  const getNewestTokens = async () => {
    const chainIdToUse = chainId ? chainId : DEFAULT_NETWORK;
    const result = await Moralis.Cloud.run("getTopSellersV2", {
      chainId: chainIdToUse,
    });
    const parseResult = JSON.parse(JSON.stringify(result));

    if (parseResult) {
      setTopSellers(parseResult);
    }
  };
  useEffect(() => {
    getNewestTokens();
  }, [chainId]);

  const TopSellerBox = ({ collection, number, backgroundColor, textColor }) => {
    const joined = (
      <>
        {`${t("general.joined")} `}
        {DateTime.fromISO(collection.createdAt)
          .setLocale(i18n.language)
          .toLocaleString({
            month: "long",
            year: "numeric",
          })}
      </>
    );
    return (
      <div
        className={
          "overflow-hidden flex border-b border-linea ease-in-out duration-500 hover:border-none hover:drop-shadow-xl hover:bg-white hover:rounded-[10px]"
        }
      >
        <div className="flex justify-center items-center p-4">
          <span className="bg-primary p-2 rounded text-white font-bold font-[20px] w-[43px] h-[43px flex items-center justify-center">
            0{number}
          </span>
        </div>
        <div className="flex-[5] flex items-center py-4">
          <Link to={`/collectionsProfile/${collection.addrs}/1`}>
            <ImageWithFallback
              border="5px solid white"
              borderRadius="40px"
              minWidth="80px"
              minHeight="80px"
              width="80px"
              weight="80px"
              src={collection.image}
              alt={collection.name}
            />
          </Link>
          <div className="flex flex-col pl-4 font-[20px] font-bold text-titulos">
            <Link to={`/collectionsProfile/${collection.addrs}/1`}
            className="text-[14px] md:text-[20px]">
              {collection.name}
            </Link>
            <span className="font-normal text-textos text-[12px] md:text-[16px]">
              Collected:{" "}
              <span className="font-bold text-titulos">
                {collection.numberOfTransactions}
              </span>
            </span>
            <span className="font-normal text-textos text-[12px] md:hidden">{joined}</span>
          </div>
        </div>
        <div className="hidden md:flex flex-[3_0] items-center text-right px-10 text-[12px] md:text-[16px]">
          {joined}
        </div>
      </div>
    );
  };

  return (
    <div className="md:py-[110px] max-w-[1440px] mx-auto">
      <div className="text-titulos text-[24px] font-bold my-8 mx-2 md:mx-20">
        {t("explore.topSellers")}
      </div>

      <Box className="mx-auto md:mx-[100px]" paddingBottom="100px">
        {topSellers && topSellers.length > 0 ? (
          <Box>
            <SimpleGrid columns={[1, 1, 2]} spacing="22px">
              {topSellers.slice(0, 8).map((collection, index) => (
                <TopSellerBox
                  key={index}
                  collection={collection}
                  number={index + 1}
                />
              ))}
            </SimpleGrid>
          </Box>
        ) : (
          <SimpleGrid columns={2} spacing="22px">
            {[1, 2, 3, 4, 5, 6, 7, 8].map((it) => (
              <Skeleton key={it} height="90px" borderRadius="10px" />
            ))}
          </SimpleGrid>
        )}
      </Box>
    </div>
  );
};
