import React from 'react';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, CloseButton } from '@chakra-ui/react';

export const ErrorBox = ({title, message, onClose}) => {
    return (<Alert status="error">
            <AlertIcon />
            <Box flex="1">
              <AlertTitle>{title}</AlertTitle>
              <AlertDescription display="block">
                {message}
              </AlertDescription>
            </Box>
            <CloseButton position="absolute" right="8px" top="8px" onClick={onClose}/>
        </Alert>)
}