import * as React from "react";
import { SVGProps } from "react";

const SearchSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={35}
    height={27}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#fff"
    {...props}
  >
    <path
      d="M12.591 23.182c5.85 0 10.591-4.741 10.591-10.59C23.182 6.741 18.441 2 12.592 2 6.741 2 2 6.742 2 12.591c0 5.85 4.742 10.591 10.591 10.591ZM21.593 18.416l11.12 6.884"
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);

export default SearchSVG;
