import { Box, Flex, Text } from "@chakra-ui/react";
import ImageWithFallback from "components/_commons/ImageWithFallback";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
const styles = {};

export default function CollectionCard({ collection, hideOwner }) {
  const banner = collection.banner ? collection.banner : collection.image;
  const { t } = useTranslation("global");
  return (
    <Flex
      key={collection._id}
      className="flex flex-col max-w-[462px] h-[360px] rounded-[10px] overflow-hidden bg-white shadow-lg mx-[15px] my-[20px] mb-[10px] md:mb-[40px]"
      as={RouterLink}
      to={`/collectionsProfile/${collection.addrs}/1`}
    >
      <Flex className="h-[108px]" overflow="hidden">
        <ImageWithFallback
          className="w-full h-full object-cover "
          src={banner}
        />
      </Flex>
      <Flex flex={1} className="relative">
        <Box paddingTop="13%" width="100%">
          <Box alignItems="center" className="px-2">
            <Text className="title truncate text-center !text-[24px]">
              {collection.name}
            </Text>
          </Box>
          <Box
            alignItems="center"
            display={hideOwner && 'none'}
            // as={RouterLink}
            // to={`/userProfile/${collection.owner}`}
          >
            <Text className="truncate text-center text-[10px] md:text-[12px] text-primary font-bold">
              <span className="text-titulos ">{t("general.by")} </span>
              <span>
                {collection.userOwner && collection.userOwner.length > 0
                  ? collection.userOwner[0].username
                  : collection.owner.substring(0, 15)}
              </span>
            </Text>
          </Box>
          <Box alignItems="center" className="m-[23px]">
            <Text
              className="text-center text-[14px] md:text-[16px] leading-[165%]"
              noOfLines={3}
            >
              {collection.description}
            </Text>
          </Box>
        </Box>
        <Box className="absolute top-0 left-[50%] translate-x-[-50%] mt-[-40px]">
          <div className={styles.container}>
            <ImageWithFallback
              className="rounded-full w-[80px] h-[80px] border-[5px] border-white border-solid"
              src={collection.image}
            />
          </div>
        </Box>
      </Flex>
    </Flex>
  );
}
