import Button from "components/_commons/Button";
import { Link as ReachLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useUserInfo from "../../../hooks/useUserInfo";
import { useToast } from "@chakra-ui/react";

export default function Hero() {
  const { t } = useTranslation("global");
  const history = useHistory();
  const toast = useToast();
  const { isAuthenticated, role } = useUserInfo();

  const handleClick = (nft) => {
    history.push("/exploreCollection");
  };

  const handleCreateClick = () => {
    if (!isAuthenticated) {
      toast({
        title: t("auth.loginRequired"),
        status: "warning",
      });
      return;
    }
    history.push("/mint");
  };

  const heroTexts = (
    <>
      <div className="text-[14px] lg:text-[24px] font-bold leading-[60px] text-center md:text-left">
        {t("explore.welcome")}
      </div>
      <div className="title md:text-white lg:text-inherit leading-[120%] text-center md:text-left">
        {t("explore.title")}
      </div>
      <div className="text-[16px] lg:text-[24px] mt-[23px] leading-[131%] text-center md:text-left">
        {t("explore.subtitle")}
        <br />
        {t("explore.subtitle2")}
      </div>

      <div className="flex justify-around lg:justify-start mt-[63px] text-[18px] font-bold mb-[30px]">
        <Button
          className="bg-primary text-white  px-10 py-3 hover:bg-secondary"
          onClick={() => handleClick()}
        >
          {t("menu.Explore")}
        </Button>
        {role && role.some((it) => it.name === "admin") && (
          <Button
            className="px-10 ml-2 border border-primary hover:bg-primary hover:text-white md:bg-white lg:bg-inherit md:text-textos "
            onClick={handleCreateClick}
          >
            {t("menu.Create")}
          </Button>
        )}
      </div>
    </>
  );

  const heroImage = (
    <img
      className="h-full w-full object-cover"
      src="/images/hero_nft_1.jpg"
      alt="hero_image"
    ></img>
  );

  return (
    <>
      <div className="md:hidden lg:block">
        <div className="grid grid-cols-1 lg:grid-cols-2 text-textos bg-white lg:h-[calc(100vh-60px)]">
          <div className=" flex justify-center items-center lg:h-[calc(100vh-60px)]">
            <div className="max-w-[550px] mx-5">{heroTexts}</div>
          </div>
          <div className="order-first lg:order-last h-[30vh] lg:h-[calc(100vh-60px)]">
            {heroImage}
          </div>
        </div>
      </div>

      <div className="hidden relative md:block lg:hidden">
        {heroImage}
        <div className="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 !text-white">
          {heroTexts}
        </div>
      </div>
    </>
  );
}
