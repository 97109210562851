import { background, Center, Spinner, Box } from "@chakra-ui/react";
import { useMoralis } from "react-moralis";

import { TopSellers } from "./components/Home/TopSellers";
import Explorer from "./components/Home/Explorer/Explorer";
import Hero from "./components/Home/Hero";
import CreateCollection from "./components/Home/CreateCollection";
import BestCollections from "./components/Home/BestCollections";
import SideBar from "./layouts/SideBar";
import { useRef, useState, useEffect } from "react";

function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(
    ([entry]) => {
      setIntersecting(entry.isIntersecting);
    },
    {
      threshold: 0.5,
    }
  );

  useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}

export const Home = () => {
  const { isInitialized } = useMoralis();
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref1IsVisible = useOnScreen(ref1);
  const ref2IsVisible = useOnScreen(ref2);
  const ref3IsVisible = useOnScreen(ref3);
  const ref4IsVisible = useOnScreen(ref4);
  const ref5IsVisible = useOnScreen(ref5);

  if (isInitialized) {
    return (
      <div className="block bg-fondo-basico">
        <div className="hidden lg:block sticky top-[64px] z-10 w-[70px]">
          <SideBar
            dotsState={[
              { href: "#Hero", isVisible: ref1IsVisible },
              { href: "#TopTen", isVisible: ref2IsVisible },
              { href: "#CreateCollection", isVisible: ref3IsVisible },
              { href: "#TopSellers", isVisible: ref4IsVisible },
              { href: "#Best", isVisible: ref5IsVisible },
            ]}
          />
        </div>
        <div id="Hero" ref={ref1} className="lg:-mt-[calc(100vh-64px)] lg:ml-[70px]">
          <Hero />
        </div>
        <div id="TopTen" ref={ref2} className="lg:ml-[70px]">
          <Explorer />
        </div>
        <div id="CreateCollection" ref={ref3} className="lg:ml-[70px]">
          <CreateCollection />
        </div>
        <div id="TopSellers" ref={ref4} className="lg:ml-[70px]">
          <TopSellers />
        </div>
        <div id="Best" ref={ref5} className="pb-[30px] lg:ml-[70px]">
          <BestCollections />
        </div>
      </div>
    );
  } else {
    return (
      <Center minHeight={400}>
        <Spinner size="xl" />
      </Center>
    );
  }
};
