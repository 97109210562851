import { SearchIcon } from "@chakra-ui/icons";
import Button from "components/_commons/Button";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import SearchSVG from "svg/search";
import styles from "./index.module.css";

interface HomeSearchProps {
  onSearch: (text: string) => void;
}

export default function HomeSearch({ onSearch }: HomeSearchProps) {
  const [t] = useTranslation("global");
  const history = useHistory();
  const [text, setText] = useState<string>("");

  const handleOnSearch = () => {
    history.push(`/finder`);
    onSearch("");
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      handleOnSearch();
    }
  };

  return (
    <div className={styles.main}>
      <Button className="" onClick={handleOnSearch}>
        <SearchSVG />
      </Button>
    </div>
  );
}
