import { Box, Input, Button, Stack, Text, useToast } from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMoralis } from "react-moralis";
import { ErrorBox } from "./Error";

export const Profile = () => {
  const toast = useToast();
  const { t } = useTranslation("global");
  const { user, setUserData, userError, isUserUpdating } = useMoralis();
  const [username, setUsername] = useState(user.attributes.username);
  const [email, setEmail] = useState(user.attributes.email);
  const [password, setPassword] = useState("");

  const handleSave = () => {
    setUserData({
      username,
      email,
      password: password === "" ? undefined : password,
    })
      .then((result) =>
        toast({
          description: t("general.success"),
          status: "success",
        })
      )
      .catch((e) => {
        toast({
          description: t("general.error"),
          status: "error",
        });
      });
  };

  return (
    <Box className="mt-20 px-4">
      <Stack spacing={3} maxW="430px" mx="auto">
        {userError && (
          <ErrorBox
            title={t("profile.errors.updateFailTitle")}
            message={userError.message}
          />
        )}
        <Box>
          <Text>{t("profile.username")}</Text>
          <Input
            value={username}
            onChange={(event) => setUsername(event.currentTarget.value)}
            maxLength={35}
          />
        </Box>
        <Box>
          <Text>{t("profile.email")}</Text>
          <Input
            value={email}
            onChange={(event) => setEmail(event.currentTarget.value)}
            maxLength={50}
          />
        </Box>
        <Box>
          <Text>{t("profile.password")}</Text>
          <Input
            value={password}
            onChange={(event) => setPassword(event.currentTarget.value)}
            maxLength={35}
          />
        </Box>
        <Button
          className="!bg-primary !text-white"
          onClick={handleSave}
          isLoading={isUserUpdating}
        >
          {t("profile.button.save")}
        </Button>
      </Stack>
    </Box>
  );
};
