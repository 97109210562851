import { ZERO_ADDRESS } from "./constants";
import { getNativeByChain } from "./networks";
import { utils, BigNumber, ethers } from "ethers";

import { useMoralisDapp } from "../providers/MoralisDappProvider/MoralisDappProvider";

export const n6 = new Intl.NumberFormat("en-us", {
  style: "decimal",
  minimumFractionDigits: 0,
  maximumFractionDigits: 6,
});
export const n4 = new Intl.NumberFormat("en-us", {
  style: "decimal",
  minimumFractionDigits: 0,
  maximumFractionDigits: 4,
});

export const c2 = new Intl.NumberFormat("en-us", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

/**
 * Returns a string of form "abc...xyz"
 * @param {string} str string to string
 * @param {number} n number of chars to keep at front/end
 * @returns {string}
 */
export const getEllipsisTxt = (str, n = 6) => {
  if (str) {
    return `${str.slice(0, n)}...${str.slice(str.length - n)}`;
  }
  return "";
};

export const tokenValue = (value, decimals = 18) =>
  value ? value / Math.pow(10, decimals) : value;

/**
 * Return a formatted string with the symbol at the end
 * @param {number} value integer value
 * @param {number} decimals number of decimals
 * @param {string} symbol token symbol
 * @returns {string}
 */
export const tokenValueTxt = (value, decimals, symbol) =>
  `${n4.format(tokenValue(value, decimals))} ${symbol}`;

/**
 * Wei is the smallest denomination of ether, and you should always make calculations in Wei and convert only for display reasons.
 * @param {*} value
 * @param {*} decimal
 * @returns
 */
export const tokenValueToWei = (Moralis, value, decimal = 18) => {
  return Moralis.Units.Token(value, decimal).toNumber();
};

export const tokenValueToWeiString = (Moralis, value, decimal = 18) => {
  return Moralis.Units.Token(value, decimal);
};

export const weiToTokenValue = (Moralis, value, decimal = 18) => {
  try {
    return Moralis.Units.FromWei(value, decimal);
  } catch (e) {
    console.warn(value);
    console.warn(e);
    return "0";
  }
};
export const tokenValueToWeiEther = (value) => {
  if (value) {
    return String(BigNumber.from(utils.parseEther(`${value}`)));
  }
  return "0";
};

export const tokenValueToWeiCustomDecimals = (value, decimals) => {
  if (value) {
    return String(BigNumber.from(utils.parseUnits(`${value}`, decimals)));
  } else return "0";
};

export const WeiToTokenEther = (value) => {
  if (value) {
    return ethers.utils.formatEther(value);
  } else return 0;
};

export const getSymbol = (erc20Token, customChainId = null) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { erc20Tokens, chainId } = useMoralisDapp();
  if (erc20Token === ZERO_ADDRESS) {
    const nativeName = getNativeByChain(customChainId || chainId);
    return nativeName;
  }

  if (erc20Tokens) {
    let founded = erc20Tokens.find(
      (it) => it.addres.toLowerCase() === erc20Token
    );
    if (founded) {
      return founded.symbol;
    }
  }
  return "Token bep20";
};
