import { ReactNode, useEffect } from "react";
import {
  Box,
  Flex,
  HStack,
  Link,
  IconButton,
  Image,
  Button,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  useDisclosure,
  useColorModeValue,
  Stack,
  Text,
  Icon,
  Collapse,
  Center,
  useToast,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { useMoralis } from "react-moralis";
import { useTranslation } from "react-i18next";
import { Auth } from "./Auth";
import AvatarSVG from "svg/Avatar";
import { Link as ReachLink, useLocation, useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import LanguagesSelector from "./translations/LanguagesSelector";
import useUserInfo from "./hooks/useUserInfo";
import HomeSearch from "./components/Finder/HomeSearch";
import { DEFAULT_NETWORK, DEFAULT_USER_REGEXP } from "./constants";
import { useMoralisDapp } from "./providers/MoralisDappProvider/MoralisDappProvider";

export const HomeLinks = [
  { label: "menu.Collections", url: "/exploreCollection" },
  { label: "menu.Latest", url: "/#TopTen" },
  // { label: "menu.Create", url: "/mint", asLogged: true },
];

export const Links = [
  { label: "menu.Profile", url: "/profile" },
  {
    label: "menu.MintNFT",
    url: "/mint",
    isProtected: true,
    validRoles: ["admin"],
  },
  { label: "menu.MyNFTs", url: "/nftBalance" },
  { label: "menu.Wallet", url: "/wallet" },
  { label: "menu.MyCollections", url: "/myCollections" },
  { label: "menu.MySells", url: "/mySells" },
  {
    label: "menu.Admin",
    url: "/admin",
    isProtected: true,
    validRoles: ["admin"],
  },
];

const NavLink = ({ children, href, onClick }: { children: ReactNode }) => (
  <Link
    as={HashLink}
    to={href}
    px={2}
    py={1}
    rounded={"md"}
    _hover={{
      textDecoration: "none",
      bg: useColorModeValue("gray.200", "gray.700"),
    }}
    href={href}
    onClick={onClick}
  >
    {children}
  </Link>
);

export default function Simple() {
  const [t] = useTranslation("global");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();
  const { logout, authenticate, isAuthenticating } = useMoralis();
  const { user, isAuthenticated, role } = useUserInfo();
  const { chainId } = useMoralisDapp();
  const location = useLocation();
  const history = useHistory();
  const { isOpen: isMenuOpen, onToggle: onToggleMenu } = useDisclosure();

  useEffect(() => {
    if (user) {
      if (user.get("bloqued")) {
        logout();
        toast({
          description: t("auth.userBloqued"),
          status: "warning",
          position: "top",
        });
      } else {
        let userName = user.attributes.username;
        if (DEFAULT_USER_REGEXP.test(userName)) {
          setTimeout(() => {
            history.push("/profile");
          }, 2000);
          toast({
            title: "User name",
            description: "Please update your username",
            status: "info",
          });
        }
      }
    }
  }, [user]);

  const printNavLink = ({ label, url, isProtected, validRoles }) => {
    if (!isProtected) {
      return (
        <NavLink key={t(label)} href={url} onClick={() => onClose()}>
          {t(label)}
        </NavLink>
      );
    } else {
      if (
        validRoles &&
        role &&
        validRoles.some((validRole) => role.some((it) => it.name === validRole))
      ) {
        return (
          <NavLink key={t(label)} href={url} onClick={() => onClose()}>
            {t(label)}
          </NavLink>
        );
      }
    }
    return null;
  };

  const HomepageNavLink = ({ label, url }) => {
    const onclick = (elementId) => {
      if (location.pathname === "/") {
        const e = document.getElementById(elementId.replace("#", ""));
        e && e.scrollIntoView();
      } else {
        history.push("/");
        setTimeout(() => {
          const e = document.getElementById(elementId.replace("#", ""));
          e && e.scrollIntoView();
        }, 1000);
      }
    };
    return (
      <Link
        key={t(label)}
        onClick={() => onclick(url)}
        px={2}
        py={1}
        rounded={"md"}
        _hover={{
          textDecoration: "none",
          bg: useColorModeValue("gray.200", "gray.700"),
        }}
      >
        {t(label)}
      </Link>
    );
  };

  const printMenuItem = ({ label, url, isProtected, validRoles }) => {
    if (!isProtected) {
      return (
        <MenuItem as={ReachLink} key={t(label)} to={url}>
          {t(label)}
        </MenuItem>
      );
    } else {
      if (
        validRoles &&
        role &&
        validRoles.some((validRole) => role.some((it) => it.name === validRole))
      ) {
        return (
          <MenuItem as={ReachLink} key={t(label)} to={url}>
            {t(label)}
          </MenuItem>
        );
      }
    }
    return null;
  };

  const MobileNavItem = ({
    label,
    iconComponent,
    children,
    href,
    isOpen,
    onToggle,
    containerClassName,
  }) => {
    return (
      <Stack spacing={4} onClick={() => onToggle()}>
        <Flex
          className={containerClassName}
          px={2}
          py={1}
          rounded={"md"}
          as={Link}
          href={href ?? "#"}
          justify={"space-between"}
          align={"center"}
          _hover={{
            textDecoration: "none",
          }}
        >
          {iconComponent}
          <Text fontWeight={600}>{label}</Text>
          {children && (
            <Icon
              as={ChevronDownIcon}
              transition={"all .25s ease-in-out"}
              transform={isOpen ? "rotate(180deg)" : ""}
              w={6}
              h={6}
            />
          )}
        </Flex>

        <Collapse
          in={isOpen}
          animateOpacity
          style={{ marginTop: "0!important" }}
        >
          <Stack
            mt={2}
            pl={4}
            borderLeft={1}
            borderStyle={"solid"}
            borderColor={useColorModeValue("gray.200", "gray.700")}
            align={"start"}
          >
            {children}
          </Stack>
        </Collapse>
      </Stack>
    );
  };

  const logo = (
    <Center margin={"0 !important"} width={70} height={70}>
      <ReachLink to={"/"}>
        <Image
          maxWidth="40px"
          objectFit="contain"
          mt="0px"
          src={"/logo.png"}
          alt="NFT Marketplace"
        />
      </ReachLink>
    </Center>
  );

  const logoutButton = (
    <div className="flex justify-center pt-2">
      <Button id="btnLogout" onClick={() => logout()}>
        {" "}
        {t("menu.Logout")}{" "}
      </Button>
    </div>
  );

  const unAutenticatedOptions = (
    <>
      <div>
        <Button
          className="!bg-secondary text-white hover:!bg-tertiary"
          id="btnConnect"
          isLoading={isAuthenticating}
          onClick={() => authenticate({ signingMessage: t("general.company") })}
        >
          {t("auth.btnConnectWallet")}
        </Button>
      </div>
      <div className="pr-10">
        <Button
          className="!bg-transparent !border !border-white hover:!bg-white hover:!text-titulos"
          onClick={() => onOpenModal()}
        >
          {t("menu.Login")}
        </Button>
      </div>
      {/* <Box maxWidth={150}>
        <LanguagesSelector />
      </Box> */}
    </>
  );

  const HomeSections = (
    <>
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          className="!bg-transparent"
          _hover={{ border: "1px solid white" }}
        >
          {t("menu.Explore")}
        </MenuButton>
        <MenuList>
          {HomeLinks.map((link, index) => (
            <MenuItem key={index}>
              <HashLink to={link.url}>{t(link.label)}</HashLink>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );

  const autenticationOptions = (
    <>
      {Links.map(printMenuItem)}
      {logoutButton}
    </>
  );

  const autenticationOptionsMovil = (
    <>
      <MobileNavItem
        label={""}
        iconComponent={<AvatarSVG />}
        isOpen={isMenuOpen}
        onToggle={onToggleMenu}
        containerClassName="border !px-5 !py-2"
      >
        {Links.map(printNavLink)}
      </MobileNavItem>
      <div className="pb-6" />
      {HomeLinks.map((it) => {
        if (it.asLogged && !isAuthenticated) return null;
        if (it.url.startsWith("#")) return HomepageNavLink(it);
        else return printNavLink(it);
      })}

      <div className="px-[8px] pt-6 pb-2">
        <Button variant="link" className="!text-white" onClick={() => logout()}>
          {t("menu.Logout")}{" "}
        </Button>
      </div>
      <LanguagesSelector fill="#fff" />
    </>
  );

  const unAutenticatedOptionsMovil = (
    <>
      <div className="pb-6" />
      {HomeLinks.map((it) => {
        if (it.asLogged && !isAuthenticated) return null;
        if (it.url.startsWith("#")) return HomepageNavLink(it);
        else return printNavLink(it);
      })}
      <div className="px-[8px] pt-6 pb-2">
        <Link
          as={ReachLink}
          to={"/login"}
          className="font-bold"
          px={2}
          py={1}
          onClick={() => onClose()}
        >
          {t("menu.Login")}
        </Link>
      </div>
      <LanguagesSelector fill="#fff" />
    </>
  );

  const InvalidNetwork = (
    <HStack spacing={8} alignItems={"center"} justifyContent="center" flex={1}>
      {logo}
      <HStack as={"nav"} spacing={4} display={{ base: "none", md: "flex" }}>
        <NavLink href={""}>{t("menu.Home")}</NavLink>
      </HStack>
      <HStack
        spacing={8}
        alignItems={"center"}
        flex={1}
        justifyContent="flex-end"
        display={{ base: "none", md: "flex" }}
      ></HStack>
      <b>This marketplace can only be used with the Binance network</b>
    </HStack>
  );

  return (
    <>
      <Box
        position="fixed"
        zIndex={99}
        width="100vw"
        color={"white"}
        pr={4}
        pl={{ base: 4, sm: 0 }}
        className="bg-secondary"
      >
        <Flex h={16} justifyContent={"space-between"} alignItems="center">
          {isAuthenticated && chainId !== DEFAULT_NETWORK ? (
            InvalidNetwork
          ) : isAuthenticated ? (
            <>
              <HStack spacing={[1, 1, 8]} alignItems={"center"}>
                {logo}
                {/* Movil */}
                <HStack
                  display={{ base: "flex", md: "none" }}
                  justifyContent="center"
                  alignItems="center"
                  marginInlineStart={0}
                >
                  <HomeSearch
                    onSearch={() => {
                      onClose();
                    }}
                  />
                </HStack>

                {/* Web */}
                <HStack
                  align={"right"}
                  as={"nav"}
                  spacing={4}
                  display={{ base: "none", md: "flex" }}
                >
                  <HomeSearch />
                </HStack>
              </HStack>
              <HStack
                as={"nav"}
                spacing={4}
                display={{ base: "none", md: "flex" }}
              >
                {HomeSections}

                {role && role.some((it) => it.name === "admin") && (
                  <Box>
                    <Link
                      as={ReachLink}
                      to={"/mint"}
                      textDecoration="none"
                      color={"white !important"}
                      bg={"gray.700"}
                      padding="10px 16px 10px 16px"
                      borderRadius={6}
                      _hover={{ textDecoration: "none" }}
                    >
                      {t("menu.Create")}
                    </Link>
                  </Box>
                )}

                {isAuthenticated && (
                  <>
                    <div>
                      <Menu>
                        <MenuButton
                          as={Button}
                          rightIcon={<ChevronDownIcon />}
                          className="!bg-transparent"
                          _hover={{ border: "1px solid white" }}
                        >
                          <AvatarSVG />
                        </MenuButton>
                        <MenuList>{autenticationOptions}</MenuList>
                      </Menu>
                    </div>
                    {/* <div className="pr-5">
                      <ReachLink to="/wallet">
                        <CustomButton>
                          <WalletSVG />
                        </CustomButton>
                      </ReachLink>
                    </div> */}
                  </>
                )}
              </HStack>
            </>
          ) : (
            <HStack
              spacing={8}
              alignItems={"center"}
              justifyContent="center"
              flex={1}
            >
              {logo}
              <HStack
                as={"nav"}
                spacing={4}
                display={{ base: "none", md: "flex" }}
              ></HStack>
              <HStack
                spacing={8}
                alignItems={"center"}
                flex={1}
                justifyContent="flex-end"
                display={{ base: "none", md: "flex" }}
              >
                {HomeSections}
                {unAutenticatedOptions}
              </HStack>
            </HStack>
          )}
          <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon w={22} h={22} />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen}
            backgroundColor={"transparent"}
            _hover={{ border: "1px solid white" }}
          />
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: "none" }}>
            <Stack as={"nav"} spacing={4} className="flex items-center">
              {isAuthenticated
                ? autenticationOptionsMovil
                : unAutenticatedOptionsMovil}
            </Stack>
          </Box>
        ) : null}
      </Box>
      <Modal isOpen={isOpenModal} size="xl" onClose={onCloseModal}>
        <ModalOverlay />
        <ModalContent top="15%">
          <ModalCloseButton />
          <ModalBody className="!px-[100px]">
            <Auth onClose={onCloseModal} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
