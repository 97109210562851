import { Menu, MenuButton, MenuItem, MenuList, Select } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Idiomas from "svg/idiomas";
import config from "./index";
import { VALID_LANGUAGES } from "./index";

export default function LanguagesSelector({ fill, showLangLabel }) {
  const [, i18n] = useTranslation("global");
  const [language, setLanguage] = useState(
    localStorage.getItem("languageLocalStorage") || i18n.language
  );

  const languages = Object.keys(config.resources);

  useEffect(() => {
    const isValidLang = VALID_LANGUAGES.some((lang) => lang === language);
    if (isValidLang) {
      localStorage.setItem("languageLocalStorage", language);
    }
  }, [language]);

  function onChange(e) {
    i18n.changeLanguage(e.target.value);
    setLanguage(e.target.value);
  }

  const onChangeLang = (lang) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };

  return (
    <Menu>
      <MenuButton>
        <div className="flex items-center">
          <Idiomas fill={fill} />
          <span className="md:hidden ml-2">{config.resources[i18n.language].label}</span>
        </div>
      </MenuButton>
      <MenuList>
        {languages &&
          languages.map((ln, i) => (
            <MenuItem
              className="!text-textos"
              onClick={() => onChangeLang(ln)}
              key={i}
            >
              {`${config.resources[ln].flag} ${config.resources[ln].label}`}
            </MenuItem>
          ))}
      </MenuList>
    </Menu>
  );
}
