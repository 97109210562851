import Button from "components/_commons/Button";
import { HashLink } from "react-router-hash-link";

import Facebook from "svg/SocialNetworks/Facebook";
import Twitter from "svg/SocialNetworks/Twitter";
import Instagram from "svg/SocialNetworks/Instagram";
import Youtube from "svg/SocialNetworks/Youtube";
import LinkedIn from "svg/SocialNetworks/LinkedIn";
import { useTranslation } from "react-i18next";
import { Link } from "@chakra-ui/react";

export default function Footer() {
  const { t } = useTranslation("global");

  const MyAccountLinks = [
    { label: t("menu.Profile"), url: "/profile" },
    { label: t("menu.MintNFT"), url: "/mint" },
    { label: t("menu.MyNFTs"), url: "/nftBalance" },
    { label: t("menu.Wallet"), url: "/wallet" },
    { label: t("menu.MyCollections"), url: "/myCollections" },
    { label: t("menu.MySells"), url: "/mySells" },
  ];

  return (
    <footer className=" bg-secondary text-white ">
      <div className="max-w-[1440px] mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-5 md:gap-3 p-10 ">
          <div className="flex flex-col mb-[30px]">
            <span className="font-semibold text-[20px] mb-3">
              {t("footer.titles.marketplace")}
            </span>
            <HashLink to="/allnfts">
              {t("footer.marketplace.allNFTS")}
            </HashLink>
            <HashLink to="/exploreCollection">
              {t("footer.marketplace.collections")}
            </HashLink>
          </div>

          <div className="flex flex-col mb-[30px]">
            <span className="font-semibold text-[20px] mb-3">
              {t("footer.titles.myAccount")}
            </span>
            {MyAccountLinks.map((link, key) => (
              <HashLink key={key} to={link.url}>{link.label}</HashLink>
            ))}
          </div>
          <div className="flex flex-col "></div>
          <div className="md:col-span-2 flex flex-col mb-[30px]">
            <span className="font-semibold text-[20px] mb-3">
              {t("footer.titles.about")}
            </span>
            <p>{t("footer.about")}</p>
            <div className="mt-8 flex">
              <Link href={t("footer.social.facebook")} target="_blank">
                <Button className="bg-primary w-[40px] h-[40px] flex justify-center items-center mr-6">
                  <Facebook />
                </Button>
              </Link>
              <Link href={t("footer.social.twitter")} target="_blank">
                <Button className="bg-primary w-[40px] h-[40px] flex justify-center items-center mr-6">
                  <Twitter />
                </Button>
              </Link>
              <Link href={t("footer.social.instagram")} target="_blank">
                <Button className="bg-primary w-[40px] h-[40px] flex justify-center items-center mr-6">
                  <Instagram />
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className=" bg-tertiary ">
        <div className="h-[73px] p-10 max-w-[1440px] mx-auto  flex justify-between items-center">
          <span>{t("footer.copyright")}</span>
          <div>
            {/* <HashLink to="#" className="mr-4">
              Privacy Policy
            </HashLink>
            <HashLink to="#" className="mr-4">
              Terms of Service
            </HashLink> */}
          </div>
        </div>
      </div>
    </footer>
  );
}
