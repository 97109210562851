import React from 'react';
import ReactDOM from 'react-dom';
import "./index.css";
import { MoralisProvider } from "react-moralis";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserRouter } from 'react-router-dom';
import { MoralisDappProvider } from "./providers/MoralisDappProvider/MoralisDappProvider";
import i18n from './translations'
import { MORALIS_SERVER_APP_ID, MORALIS_SERVER_URL } from './constants'


i18next.init(i18n);

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

const theme = extendTheme({ config })
const appId = MORALIS_SERVER_APP_ID;
const serverUrl = MORALIS_SERVER_URL;

ReactDOM.render(
  <React.StrictMode>
    <MoralisProvider appId={appId} serverUrl={serverUrl}>
      <ChakraProvider theme={theme}>
        <MoralisDappProvider>
          <BrowserRouter>
            <I18nextProvider i18n={i18next}>
              <App />
             </I18nextProvider>
           </BrowserRouter>
         </MoralisDappProvider>
       </ChakraProvider>
     </MoralisProvider>
   </React.StrictMode>
  ,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
