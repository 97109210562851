import * as React from "react"
import { SVGProps } from "react"

const AvatarSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={34}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17 32c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C8.716 2 2 8.716 2 17c0 8.284 6.716 15 15 15Z"
      stroke="#fff"
      strokeWidth={3}
      strokeMiterlimit={10}
    />
    <path
      d="M19.125 22.425h-4.25a6.376 6.376 0 0 0-6.377 6.377s3.188 2.883 8.502 2.883c5.313 0 8.502-2.883 8.502-2.883a6.376 6.376 0 0 0-6.377-6.377ZM12.04 13.924a4.96 4.96 0 1 1 9.92 0c0 2.739-2.221 5.668-4.96 5.668-2.74 0-4.96-2.93-4.96-5.668Z"
      fill="#fff"
    />
  </svg>
)

export default AvatarSVG
