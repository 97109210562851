import { HashLink } from "react-router-hash-link";
import styles from "./index.module.css";
import LanguagesSelector from "translations/LanguagesSelector";
import { useTranslation } from "react-i18next";
import { Link } from "@chakra-ui/react";
import Button from "components/_commons/Button";

import Facebook from "svg/SocialNetworks/Facebook";
import Twitter from "svg/SocialNetworks/Twitter";
import Instagram from "svg/SocialNetworks/Instagram";

interface SlideBarProps {
  dotsState: { href: string; isVisible: boolean }[];
}

export default function SideBar({ dotsState }: SlideBarProps) {
  const { t } = useTranslation("global");
  return (
    <div className={styles.sideBar}>
      <div>
        {dotsState.map((section, index) => (
          <HashLink smooth to={section.href} key={index}>
            <div
              className={`${styles.circle} ${
                section.isVisible ? "bg-primary" : "bg-inactive"
              } `}
            ></div>
          </HashLink>
        ))}
      </div>
      <div className="absolute bottom-3 text-teal-50">
        <div>
          <Link href={t("footer.social.facebook")} target="_blank">
            <Button className="rounded-full bg-primary w-[30px] h-[30px] flex justify-center items-center mb-[15px]">
              <Facebook width={20} height={20} />
            </Button>
          </Link>
          <Link href={t("footer.social.twitter")} target="_blank">
            <Button className="rounded-full bg-primary w-[30px] h-[30px] flex justify-center items-center mb-[15px]">
              <Twitter width={20} height={20} />
            </Button>
          </Link>
          <Link href={t("footer.social.instagram")} target="_blank">
            <Button className="rounded-full bg-primary w-[30px] h-[30px] flex justify-center items-center mb-[15px]">
              <Instagram width={20} height={20}/>
            </Button>
          </Link>
        </div>
        <LanguagesSelector />
      </div>
    </div>
  );
}
