import * as React from "react"
import { SVGProps } from "react"

const IdiomasSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={31}
    height={31}
    fill="#5A606F"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.632 1.178h1.674c.062 0 .124.062.186.062 1.24.062 2.418.31 3.534.744 5.084 1.922 8.246 5.58 9.3 10.912.124.558.124 1.178.248 1.798v1.054c-.062.434-.062.93-.124 1.364a13.825 13.825 0 0 1-1.674 4.96c-.062.124-.062.31-.062.434.434 1.55.806 3.038 1.24 4.526.186.744.434 1.488.62 2.232v.062c-.124 0-.186 0-.31-.062-2.046-.62-4.092-1.24-6.138-1.922-.248-.062-.434-.062-.62.062-1.736.992-3.596 1.674-5.642 1.86-.31 0-.62.062-.93.124H14.88c-.062 0-.186-.062-.248-.062-2.48-.124-4.774-.93-6.882-2.294-3.348-2.232-5.394-5.332-6.138-9.3-.124-.558-.124-1.054-.248-1.612v-1.674c0-.062.062-.186.062-.248.186-2.17.806-4.278 1.922-6.138C5.58 4.402 8.804 2.17 13.02 1.364c.558-.062 1.116-.124 1.612-.186Zm12.524 25.668c0-.124-.062-.186-.062-.248-.372-1.364-.744-2.666-1.116-4.03-.124-.434-.062-.744.124-1.116.93-1.612 1.55-3.348 1.736-5.208.186-2.418-.248-4.65-1.364-6.758-2.232-4.092-5.766-6.324-10.416-6.634-2.48-.186-4.774.496-6.944 1.736-4.526 2.666-6.944 8.06-5.828 13.144.93 4.216 3.348 7.254 7.316 8.99 2.604 1.178 5.332 1.302 8.06.558 1.302-.372 2.48-.93 3.658-1.674.186-.124.31-.124.496-.062.744.248 1.488.496 2.232.682.682.186 1.364.434 2.108.62Z"
    />
    <path
      d="M24.49 15.252a9.023 9.023 0 0 1-9.052 9.052c-5.022 0-9.114-4.092-9.114-9.052 0-5.022 4.092-9.052 9.114-9.052 4.96 0 9.052 4.092 9.052 9.052ZM13.02 13.64v3.286h4.836V13.64H13.02Zm4.65-1.674c-.062-.248-.062-.434-.124-.682-.248-.992-.558-1.984-1.178-2.852-.62-.806-1.116-.806-1.736 0-.744 1.054-1.054 2.232-1.302 3.472 1.426.062 2.852.062 4.34.062Zm-4.402 6.572c.062.248.124.496.124.682.248.992.558 1.922 1.116 2.728.62.868 1.178.868 1.798 0 .496-.744.806-1.55 1.054-2.356.062-.372.186-.682.248-1.054h-4.34Zm-1.922-4.96H8.122a7.35 7.35 0 0 0 0 3.286h3.224v-3.286Zm8.246 3.286h3.038a7.35 7.35 0 0 0 0-3.286h-3.038v3.286ZM12.648 8.37c-1.736.682-3.038 1.86-3.906 3.534h2.852c.248-1.178.558-2.356 1.054-3.534ZM11.594 18.6H8.742c.806 1.736 2.728 3.224 3.906 3.534-.496-1.116-.806-2.356-1.054-3.534Zm10.416-6.634c-.868-1.612-2.046-2.79-3.782-3.534.558 1.178.868 2.356 1.116 3.534h2.666Zm0 6.572h-2.604c-.372 1.178-.682 2.356-1.054 3.472 1.612-.682 2.852-1.86 3.658-3.472Z"
    />
  </svg>
)

export default IdiomasSVG
