import Button from "components/_commons/Button";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function CreateCollection() {
  const { t } = useTranslation("global");
  return (
    <div
      id="createCollection"
      className="grid grid-cols-1 md:grid-cols-3 text-textos bg-white relative h-[calc(100vh+5px)]"
    >
      <img
        className="absolute top-0 left-0 h-full w-full object-cover"
        src="/images/hero_nft_2.jpg"
      ></img>
      <div className="md:hidden absolute top-0 left-0 h-full w-full bg-black/50"></div>
      <div className="z-[1] h-full col-span-2 flex justify-center items-center">
        <div className="mx-5 md:mx-0 md:max-w-[550px] mx-auto text-white">
          <div className="title !text-white leading-[100%] !text-[40px] md:!text-[64px]">
            {t("explore.createCollectionTitle")}
          </div>
          <div className="max-w-[550px] mt-5 text-[16px] md:text-[24px] leading-[165%]">
            {t("explore.createCollectionDescription")}
          </div>

          <div className="flex mt-[75px] text-[18px]">
            <Link to="/mint">
              <Button className="bg-primary text-white text-[14px] md:text-[24px]  font-bold px-[40px] py-[12px] md:py-[22px]">
                {t("explore.createCollectionButton")}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
