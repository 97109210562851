import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={21}
    fill="#fff"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 21"
    {...props}
  >
    <path
      d="M14.541 21H6.396c-.277-.036-.552-.089-.83-.105-3.071-.175-5.41-2.4-5.497-5.449a180.546 180.546 0 0 1-.002-9.9C.137 2.852 1.947.821 4.58.254 5.142.132 5.72.084 6.29 0h8.462c.241.034.481.084.722.098 2.694.158 4.703 1.803 5.332 4.412.149.62.198 1.263.293 1.895v8.085c-.035.31-.087.617-.105.927-.154 2.665-1.815 4.67-4.431 5.287-.661.155-1.348.2-2.023.295Zm4.56-10.508c0-1.487.041-2.975-.009-4.46-.084-2.52-1.511-3.98-4.046-4.036a208.788 208.788 0 0 0-9.038 0c-2.55.055-3.998 1.492-4.054 4.02a198.906 198.906 0 0 0-.001 8.919c.059 2.592 1.507 4.018 4.117 4.07 2.995.058 5.992.056 8.986-.005 2.494-.05 3.95-1.523 4.034-3.996.052-1.503.01-3.008.01-4.512Z"
    />
    <path
      d="M5.095 10.472c.018-3.01 2.436-5.383 5.463-5.36 3.03.022 5.417 2.432 5.386 5.436-.031 2.988-2.43 5.342-5.437 5.333-3.03-.01-5.431-2.408-5.412-5.41Zm5.448-3.455c-1.973-.007-3.525 1.519-3.53 3.47a3.465 3.465 0 0 0 3.456 3.49c1.975.015 3.547-1.506 3.557-3.443a3.462 3.462 0 0 0-3.483-3.517ZM16.161 3.645c.726-.002 1.265.53 1.264 1.249 0 .702-.567 1.272-1.261 1.27-.685 0-1.283-.59-1.285-1.268-.002-.687.574-1.25 1.282-1.251Z"
    />
  </svg>
)

export default SvgComponent
