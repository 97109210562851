import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={10}
    height={21}
    fill="#fff"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 10 21"
    {...props}
  >
    <path
      d="M6.09 20.445H2.03v-9.742H0V7.347h2.03V5.331C2.03 2.592 3.173.964 6.428.964h2.71V4.32H7.445c-1.267 0-1.351.47-1.351 1.345l-.006 1.681h3.069l-.36 3.356H6.09v9.742Z"
    />
  </svg>
)

export default SvgComponent
