import { SimpleGrid, Skeleton, useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNFTDBNewNfts } from "../../../hooks/useNFTDBNewNfts";
import NFTCard from "../Shared/NFTCard";
import { useHistory } from "react-router-dom";

const EXPLORER_PAGE_SIZE = 8;

export default function Explorer() {
  const [t] = useTranslation("global");
  const [allTokens, setAllTokens] = useState([]);
  const [skip, setSkip] = useState<number>(0);
  const { newestTokens, fetching } = useNFTDBNewNfts(skip, EXPLORER_PAGE_SIZE);

  const history = useHistory();

  useEffect(() => {
    const distinc = newestTokens.filter(
      (newNft) => !allTokens.find((it) => it.objectId === newNft.objectId)
    );
    setAllTokens([...allTokens, ...distinc]);
  }, [newestTokens]);

  const handleNFTClic = (nft) => {
    history.push("/nft/" + nft.nftContractAddress + "/" + nft.tokenId);
  };

  const onMoreArtClick = () => {
    if (skip > allTokens.length) {
    } else {
      setSkip(skip + EXPLORER_PAGE_SIZE);
    }
  };

  return (
    <div className="container max-w-[1440px] mx-auto px-4 py-[60px] md:py-[140px] ">
      <div className="title md:!text-[36px] mb-[21px]">
        {t("explore.exploreCollections")}
      </div>

      {fetching || allTokens.length === 0 ? (
        <SimpleGrid columns={[1, 2, 3, 4]} spacing={10}>
          {[1, 2, 3, 4, 5, 6, 7, 8].map((it) => (
            <Skeleton key={it} height="300px" borderRadius="15px" />
          ))}
        </SimpleGrid>
      ) : (
        <SimpleGrid columns={[1, 2, 3, 4]} spacing={10}>
          {allTokens.map((nft, index) => (
            <NFTCard
              key={index}
              nft={nft}
              index={index}
              handleClic={handleNFTClic}
            />
          ))}
        </SimpleGrid>
      )}

      {/* <Center>
        {fetching ? (
          <Spinner />
        ) : (
          <Button backgroundColor="#CD9B4C" onClick={onMoreArtClick}>
            Explore more art
          </Button>
        )}
      </Center> */}
    </div>
  );
}
