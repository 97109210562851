import React, { useEffect } from "react";
import { useState } from "react";
import { useMoralis } from "react-moralis";
import {
  Button,
  Input,
  Stack,
  Heading,
  SimpleGrid,
  Box,
  InputRightElement,
  InputGroup,
  Icon,
} from "@chakra-ui/react";
import { ErrorBox } from "./Error";
import { useTranslation } from "react-i18next";
import { BiHide, BiShow } from "react-icons/bi";

const Login = () => {
  const [t] = useTranslation("global");

  const { login, isAuthenticating, isAuthenticated } = useMoralis();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const styles = {
    input: {
      backgroundColor: "white",
      color: "#000",
    },
  };

  return (
    <Stack spacing={3} mx="1">
      <div className="text-titulos text-[36px] font-bold">
        {t("auth.tittle")}
      </div>

      <Input
        placeholder={t("auth.userName")}
        value={username}
        onChange={(event) => setUsername(event.currentTarget.value)}
        maxLength={35}
        style={styles.input}
      />

      <InputGroup size="md">
        <Input
          type={show ? "text" : "password"}
          placeholder={t("auth.password")}
          value={password}
          onChange={(event) => setPassword(event.currentTarget.value)}
          maxLength={35}
          style={styles.input}
        />
        <InputRightElement width="4.5rem">
          <Button
            variant="link"
            h="1.75rem"
            size="sm"
            _focus={{ border: "none" }}
            onClick={handleClick}
          >
            {show ? (
              <Icon as={BiHide} w={6} h={6} />
            ) : (
              <Icon as={BiShow} w={6} h={6} />
            )}
          </Button>
        </InputRightElement>
      </InputGroup>

      <Button
        className="font-bold !text-titulos !text-[18px] !bg-transparent !bg-primary !text-white"
        id="btnLogin"
        isLoading={isAuthenticating}
        onClick={() => login(username, password)}
      >
        {t("auth.btnLogin")}
      </Button>
    </Stack>
  );
};

export const Auth = ({ onClose }) => {
  const [t] = useTranslation("global");
  const { authenticate, isAuthenticating, authError, isAuthenticated } =
    useMoralis();
  const [hideError, setHideError] = useState(false);

  useEffect(() => {
    if (isAuthenticating) setHideError(false);
    if (isAuthenticated) onClose();
  }, [isAuthenticating, isAuthenticated]);

  return (
    <Stack spacing={6} className="md:max-w-[430px] mx-3 md:mx-auto" py="30px">
      {authError && !hideError && (
        <ErrorBox
          title="Authentication has failed"
          message={authError.message}
          onClose={() => setHideError(true)}
        />
      )}
      <Stack spacing={3}>
        <Button
          className="md:!hidden !bg-secondary text-white hover:!bg-tertiary" 
          id="btnConnect"
          isLoading={isAuthenticating}
          onClick={() => authenticate({signingMessage: t('general.company')})}
        >
          {t("auth.btnConnectWallet")}
        </Button>
        <div className="md:!hidden">
          <hr />
        </div>
        {/*<SignUp />*/}
        <Login />
      </Stack>
    </Stack>
  );
};
