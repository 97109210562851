import { Image, ImageProps } from "@chakra-ui/react";
import fallbackImg from "../../helpers/fallbackImg";

export default function ImageWithFallback({
  src,
  style,
  alt = "no-alt",
  ...rest
}: ImageProps) {
  return (
    <Image
      src={src || "error"}
      alt={alt}
      style={style}
      fallbackSrc={fallbackImg}
      {...rest}
    />
  );
}
